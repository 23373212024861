@import url("../fonts/stylesheet.css");
/* http://meyerweb.com/eric/tools/css/reset/
     v2.0 | 20110126
     License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* general */
html {
  height: 100%;
}

body {
  height: 100%;
  min-width: 1084px;
  background-color: #fff;
  color: #181818;
  font: 16px/1.2 Geometria,Arial,Helvetica,sans-serif;
}

@media (max-width: 1103px) {
  body {
    min-width: 0;
  }
}

.success-body,
.buy-body,
.trial-body {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

select, input, textarea, button {
  color: inherit;
  font-family: Geometria,Arial,Helvetica,sans-serif;
  font-size: 16px;
}

:focus {
  outline: none;
}

.container {
  width: 1024px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1103px) {
  .container {
    width: auto;
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media (max-width: 575px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.svg-sprite {
  display: none;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.logo {
  width: 146px;
  height: 48px;
  background-position: center center;
  background-repeat: no-repeat;
}

@media (max-width: 575px) {
  .logo {
    width: 121px;
    height: 40px;
    background-size: contain;
  }
}

.contacts {
  font-weight: 700;
  text-align: right;
}

.phone {
  font-size: 24px;
}

.email {
  font-size: 18px;
  border-bottom: 1px solid #181818;
}

.bg {
  background: url("/i/bg.jpg") center top no-repeat;
  background-size: cover;
}

@media (max-width: 880px) {
  .bg {
    background-image: url("/i/bg-mobile.jpg");
  }
}

@media (max-width: 320px) {
  .bg {
    background-image: url("/i/bg-320.jpg");
  }
}

.button {
  position: relative;
  display: inline-block;
  height: 48px;
  padding: 0 55px 0 22px;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  vertical-align: middle;
  box-sizing: border-box;
  text-decoration: none;
  background: #ffd71f;
  border-radius: 24px;
  box-shadow: none;
  border: 2px solid #181818;
  cursor: pointer;
  white-space: nowrap;
}

.button::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 22px;
  width: 18px;
  height: 14px;
  margin-top: -7px;
  background: url("../i/arrow.svg") center center no-repeat;
  transition: right 0.2s ease;
}

.button:hover {
  text-decoration: none;
}

.button:hover::after {
  right: 14px;
}

.button-secondary {
  position: relative;
  display: inline-block;
  height: 48px;
  padding: 0 55px 0 22px;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  vertical-align: middle;
  box-sizing: border-box;
  text-decoration: none;
  background: #ffd71f;
  border-radius: 24px;
  box-shadow: none;
  border: 2px solid #181818;
  cursor: pointer;
  white-space: nowrap;
  background: none;
  border-color: #ffd71f;
}

.button-secondary::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 22px;
  width: 18px;
  height: 14px;
  margin-top: -7px;
  background: url("../i/arrow.svg") center center no-repeat;
  transition: right 0.2s ease;
}

.button-secondary:hover {
  text-decoration: none;
}

.button-secondary:hover::after {
  right: 14px;
}

.button-secondary::after {
  background-image: url("../i/arrow-white.svg");
}

.button-narrow {
  padding-right: 39px;
}

.button-narrow::after {
  right: 14px;
}

.button-narrow:hover::after {
  right: 8px;
}

.button-yellow {
  border: none;
}

h2 {
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}

header,
.start {
  color: #ffffff;
}

header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 29px;
}

@media (max-width: 767px) {
  header {
    align-items: center;
    padding-top: 43px;
  }
}

header .logo {
  background-image: url("/i/logo-white.svg");
}

@media (max-width: 767px) {
  header .contacts {
    display: none;
  }
}

header .email {
  border-bottom-color: #ffffff;
}

.lang-switcher {
  width: 100%;
  margin-bottom: 16px;
  text-align: right;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .lang-switcher {
    width: auto;
    order: 2;
    margin-bottom: 0;
  }
}

.lang-switcher li {
  display: inline-block;
}

.lang-switcher li:first-child {
  margin-right: 2px;
}

.lang-switcher li a {
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.lang-switcher li a:hover {
  opacity: 1;
}

.start {
  padding-top: 146px;
  padding-bottom: 216px;
  text-align: center;
}

.en .start {
  width: 900px;
}

@media (max-width: 1103px) {
  .en .start {
    width: auto;
  }
}

@media (max-width: 880px) {
  .start {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .start {
    padding-top: 41px;
    padding-bottom: 47px;
  }
}

@media (max-width: 575px) {
  .start {
    text-align: left;
  }
}

.start h1 {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 96px;
  line-height: 1;
}

@media (max-width: 880px) {
  .start h1 {
    font-size: 64px;
    line-height: 54px;
  }
}

@media (max-width: 767px) {
  .start h1 {
    margin-bottom: 29px;
  }
}

.start p {
  font-weight: 500;
  font-size: 48px;
}

@media (max-width: 880px) {
  .start p {
    font-size: 24px;
    line-height: 32px;
  }
}

.line {
  background: #ffd71f;
}

.line .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
}

@media (max-width: 1103px) {
  .line .container {
    display: block;
    height: auto;
    padding-top: 30px;
    padding-bottom: 37px;
  }
}

.line .text {
  font-size: 22px;
  line-height: 30px;
}

@media (max-width: 1103px) {
  .line .text {
    margin-bottom: 35px;
  }
}

.services {
  margin-bottom: 116px;
  padding-top: 108px;
}

@media (max-width: 767px) {
  .services {
    padding-top: 52px;
  }
}

@media (max-width: 575px) {
  .services {
    margin-bottom: 82px;
  }
}

.services h2 {
  margin-bottom: 66px;
  font-size: 40px;
}

@media (max-width: 767px) {
  .services h2 {
    margin-bottom: 41px;
    font-size: 30px;
  }
}

.services li {
  display: flex;
  align-items: flex-end;
  padding: 35px 24px 51px 48px;
  color: #ffffff;
  border-radius: 32px;
  background-color: #221914;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 880px) {
  .services li {
    display: block;
  }
}

@media (max-width: 767px) {
  .services li {
    padding: 37px 12px 38px;
    border-radius: 16px;
  }
}

.services li:not(:last-child) {
  margin-bottom: 60px;
}

@media (max-width: 575px) {
  .services li:not(:last-child) {
    margin-bottom: 24px;
  }
}

.services li:nth-child(1) {
  background-image: url("/i/services1.jpg");
}

.services li:nth-child(2) {
  background-image: url("/i/services2.jpg");
}

.services li:nth-child(3) {
  background-image: url("/i/services3.jpg");
}

.services li:nth-child(4) {
  background-image: url("/i/services4.jpg");
}

.services .col:first-child {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 518px;
  height: 426px;
}

@media (max-width: 1103px) {
  .services .col:first-child {
    width: 55%;
    height: auto;
  }
}

@media (max-width: 880px) {
  .services .col:first-child {
    width: auto;
    margin-bottom: 45px;
  }
}

.services .col:last-child {
  flex: 1 1 auto;
}

@media (max-width: 1103px) {
  .services .col:last-child {
    flex: 0 0 auto;
    width: 45%;
  }
}

@media (max-width: 880px) {
  .services .col:last-child {
    width: auto;
  }
}

.services .col-alt {
  padding-left: 61px;
}

@media (max-width: 1103px) {
  .services .col-alt {
    padding-left: 0;
  }
}

.services h3 {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 48px;
  line-height: 1;
}

@media (max-width: 767px) {
  .services h3 {
    font-size: 32px;
    line-height: 36px;
  }
}

.services .subtitle {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 30px;
}

@media (max-width: 1103px) {
  .services .subtitle {
    padding-right: 24px;
  }
}

@media (max-width: 767px) {
  .services .subtitle {
    font-size: 20px;
    line-height: 24px;
  }
}

.services .text {
  margin-top: auto;
  padding-right: 48px;
  line-height: 24px;
}

@media (max-width: 880px) {
  .services .text {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .services .text {
    font-size: 14px;
    line-height: 18px;
  }
}

.services .price {
  display: flex;
  flex-wrap: wrap;
  padding-right: 24px;
}

@media (max-width: 767px) {
  .services .price {
    padding-right: 12px;
  }
}

@media (max-width: 575px) {
  .services .price {
    display: block;
    margin-bottom: 33px;
  }
}

.services .price-item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 25px;
}

@media (max-width: 1103px) {
  .services .price-item {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .services .price-item {
    display: block;
    margin-bottom: 14px;
  }
}

.services .price-item:first-child {
  margin-right: 30px;
}

@media (max-width: 575px) {
  .services .price br {
    display: none;
  }
}

.services .value {
  margin-right: 10px;
  font-weight: 700;
  font-size: 52px;
  line-height: 47px;
}

@media (max-width: 767px) {
  .services .value {
    font-size: 48px;
  }
}

.services .property {
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 1103px) {
  .services .property {
    white-space: nowrap;
    margin-top: 5px;
  }
}

@media (max-width: 575px) {
  .services .property {
    white-space: normal;
  }
}

.services .buttons {
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .services .buttons {
    margin-bottom: 7px;
  }
}

.services button {
  margin-bottom: 20px;
}

.services button:first-child {
  margin-right: 10px;
}

@media (max-width: 360px) {
  .services button:first-child.button-narrow {
    margin-right: 0;
  }
}

@media (max-width: 575px) {
  .services .button-narrow {
    padding-right: 37px;
    padding-left: 10px;
  }
  .services .button-narrow::after {
    right: 12px;
  }
}

.services .tip {
  font-size: 14px;
  line-height: 18px;
  padding-right: 24px;
}

@media (max-width: 767px) {
  .services .tip {
    padding-right: 12px;
  }
}

.services .tip-alt {
  margin-bottom: 65px;
  font-size: 24px;
  line-height: 30px;
}

.files {
  margin-bottom: 103px;
}

@media (max-width: 575px) {
  .files {
    margin-bottom: 56px;
  }
}

.files h2 {
  margin-bottom: 41px;
}

@media (max-width: 575px) {
  .files h2 {
    margin-bottom: 45px;
  }
}

.files ul {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1103px) {
  .files ul {
    flex-wrap: wrap;
  }
}

.files li {
  flex: 0 0 auto;
}

@media (max-width: 1103px) {
  .files li {
    width: 33.3333%;
    margin-bottom: 16px;
  }
}

@media (max-width: 575px) {
  .files li {
    width: 50%;
  }
}

.files a {
  display: block;
  width: 154px;
  text-align: center;
  padding: 12px 0 14px;
  border-radius: 24px;
  line-height: 24px;
}

@media (max-width: 1103px) {
  .files a {
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .files a {
    width: 120px;
  }
}

.files a:hover {
  background: #ffd71f;
  box-shadow: 0 14px 28px 0 #ffe2bd;
}

.files a:hover span, .files a:hover p {
  color: #000000;
}

.files span {
  display: inline-block;
  margin-bottom: 3px;
  font-size: 18px;
  line-height: 1;
  border-bottom: 1px solid #181818;
}

.files p {
  font-size: 14px;
  color: #a0a0a0;
}

.teachers {
  margin-bottom: 100px;
}

@media (max-width: 1103px) {
  .teachers {
    margin-bottom: 78px;
  }
}

.teachers h2 {
  margin-bottom: 41px;
}

@media (max-width: 575px) {
  .teachers h2 {
    margin-bottom: 47px;
  }
}

.teachers ul {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1103px) {
  .teachers ul {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media (max-width: 575px) {
  .teachers ul {
    display: block;
  }
}

@media (max-width: 1103px) {
  .teachers li {
    flex: 0 0 auto;
    width: 50%;
    margin-bottom: 35px;
  }
}

@media (max-width: 575px) {
  .teachers li {
    width: auto;
  }
}

.teachers img {
  display: block;
  width: 225px;
  height: 225px;
  margin: 0 auto 21px;
  border-radius: 24px;
  box-shadow: 0 14px 28px 0 #f0f0f0;
}

.teachers p {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.features {
  margin-bottom: 45px;
}

@media (max-width: 575px) {
  .features {
    margin-bottom: 62px;
  }
}

.features h2 {
  margin-bottom: 56px;
}

@media (max-width: 575px) {
  .features h2 {
    margin-bottom: 44px;
  }
}

.features ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -31px;
  counter-reset: features;
  text-align: center;
}

@media (max-width: 1103px) {
  .features ul {
    margin: 0 -20px;
  }
}

.features li {
  flex: 0 0 auto;
  width: 33.3333%;
  margin-bottom: 66px;
  padding: 0 31px;
  box-sizing: border-box;
  line-height: 20px;
}

@media (max-width: 1103px) {
  .features li {
    width: 360px;
    max-width: 100%;
    margin-bottom: 43px;
    padding: 0 20px;
  }
}

.features li::before {
  counter-increment: features;
  content: "0" counter(features);
  display: block;
  margin-bottom: 17px;
  font-weight: 900;
  font-size: 48px;
  line-height: 0.75;
  color: #ffd71f;
}

.features .title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
}

.reviews {
  position: relative;
  margin-bottom: 137px;
}

@media (max-width: 640px) {
  .reviews {
    margin-bottom: 63px;
  }
}

.reviews h2 {
  margin-bottom: 40px;
}

@media (min-width: 640px) {
  .reviews h2 br {
    display: none;
  }
}

.reviews .text {
  margin-bottom: 20px;
  line-height: 24px;
}

.reviews .author {
  font-weight: 700;
}

.carousel {
  width: 600px;
  margin: 0 auto;
  padding: 36px 60px 78px;
  border-radius: 24px;
  box-shadow: 0 14px 28px 0 #f0f0f0;
}

@media (max-width: 880px) {
  .carousel {
    width: auto;
  }
}

@media (max-width: 767px) {
  .carousel {
    padding: 40px 24px 78px;
  }
}

.carousel-progress {
  position: absolute;
  right: 0;
  bottom: 27px;
  left: 0;
  font-weight: 700;
  font-size: 12px;
  color: #a0a0a0;
  text-align: center;
}

@media (max-width: 575px) {
  .carousel-progress {
    bottom: 37px;
  }
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  margin-top: -24px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #ffd71f url("/i/arrow.svg") center center no-repeat;
  background-size: 19px 13px;
}

@media (max-width: 767px) {
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    top: -81px;
    margin-top: 0;
  }
}

@media (max-width: 639px) {
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    top: -120px;
  }
  .en .owl-carousel .owl-nav button.owl-prev, .en
  .owl-carousel .owl-nav button.owl-next {
    top: -78px;
  }
}

.owl-carousel .owl-nav button.owl-prev {
  transform: rotate(180deg);
  left: -24px;
}

@media (max-width: 767px) {
  .owl-carousel .owl-nav button.owl-prev {
    left: 0;
  }
}

.owl-carousel .owl-nav button.owl-next {
  right: -24px;
}

@media (max-width: 767px) {
  .owl-carousel .owl-nav button.owl-next {
    right: 0;
  }
}

footer {
  padding-top: 50px;
  padding-bottom: 86px;
}

@media (max-width: 767px) {
  footer {
    text-align: center;
  }
}

@media (max-width: 575px) {
  footer {
    padding-top: 45px;
    padding-bottom: 75px;
  }
}

footer .logo {
  background-image: url("/i/logo-black.svg");
}

@media (max-width: 767px) {
  footer .logo {
    display: none;
  }
}

@media (max-width: 767px) {
  footer .contacts {
    text-align: center;
  }
}

.footer-top {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .footer-top {
    display: block;
    margin-bottom: 37px;
  }
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .footer-bottom {
    display: block;
  }
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .links {
    display: block;
    margin-bottom: 29px;
  }
}

.links a {
  font-size: 14px;
  color: inherit;
  border-bottom: 1px solid #181818;
  text-decoration: none;
}

.address {
  text-align: right;
}

@media (max-width: 767px) {
  .address {
    margin-bottom: 26px;
    text-align: center;
  }
}

.copy {
  font-size: 13px;
}

@media (max-width: 767px) {
  .copy-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .copy-mobile {
    display: none;
  }
}

.trial-opener {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -96px;
  z-index: 9;
  margin: auto 0;
  border-radius: 0 0 24px 24px;
  width: 240px;
  height: 48px;
  background-color: #ffd71f;
  font-weight: bold;
  line-height: 47px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media (max-width: 767px) {
  .trial-opener {
    display: none;
  }
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none;
  text-align: center;
  background: rgba(0, 0, 0, 0.65);
  overflow-y: auto;
}

.cover::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
}

@media (max-width: 690px) {
  .cover::after {
    display: none;
  }
}

.modal {
  position: relative;
  display: inline-block;
  padding: 47px 40px 40px;
  box-sizing: border-box;
  text-align: left;
  vertical-align: middle;
  border-radius: 24px;
  background: #ffffff;
}

@media (max-width: 690px) {
  .modal {
    padding: 67px 24px 56px;
  }
}

.modal .tip {
  margin-bottom: 20px;
  line-height: 20px;
}

.modal .title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.modal-header {
  margin-bottom: 60px;
}

@media (max-width: 690px) {
  .modal-header {
    margin-bottom: 45px;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background: url("/i/close.svg") no-repeat;
  cursor: pointer;
}

.close:hover {
  opacity: 0.7;
}

.form-cols {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 690px) {
  .form-cols {
    display: block;
  }
}

.form-cols .form-item {
  width: 250px;
  margin-right: 20px;
}

@media (max-width: 690px) {
  .form-cols .form-item {
    width: auto;
    margin-right: 0;
  }
}

.form-cols .form-item:not(:last-child) {
  margin-bottom: 24px;
}

@media (max-width: 690px) {
  .form-cols .form-item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.form-cols .textarea-item {
  width: 520px;
}

@media (max-width: 690px) {
  .form-cols .textarea-item {
    width: auto;
    margin-right: 0;
  }
}

.form-item {
  height: 48px;
}

.form-submit {
  margin-top: 60px;
}

@media (max-width: 690px) {
  .form-submit {
    margin-top: 52px;
  }
}

.textarea-item {
  height: 120px;
}

select,
input,
textarea {
  margin: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #d2d2d2;
  padding: 0 24px;
  box-sizing: border-box;
  border-radius: 24px;
}

select::-webkit-input-placeholder,
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #a0a0a0;
  font-size: 16px;
}

select::-moz-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #a0a0a0;
  font-size: 16px;
}

select:-ms-input-placeholder,
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #a0a0a0;
  font-size: 16px;
}

select:-moz-placeholder,
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #a0a0a0;
  font-size: 16px;
}

select::placeholder,
input::placeholder,
textarea::placeholder {
  color: #a0a0a0;
  font-size: 16px;
}

textarea {
  padding-top: 15px;
  padding-bottom: 15px;
  resize: none;
}

.checkbox-item input {
  display: none;
}

.checkbox-label {
  position: relative;
  display: block;
  padding-left: 50px;
  font-weight: 500;
}

.checkbox-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
}

input:checked ~ .checkbox-label::before {
  border: none;
  background: #ffd71f url("/i/check.svg") center center no-repeat;
}

@media (max-width: 690px) {
  .checkbox-label::before {
    top: 3px;
    margin-top: 0;
  }
}

.cselect {
  font-size: 16px;
}

.custom_select {
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 400;
}

.custom_select .cs_current_after {
  top: 50%;
  right: 24px;
  width: 0;
  height: 0;
  margin-top: -3px;
  border-width: 6px 5px 0 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}

.custom_select.active .cs_current_after {
  transform: rotate(180deg);
}

.custom_select .scroll-container {
  max-height: 200px;
  margin: 0 -1px;
  border-width: 1px;
  background-color: #ffffff;
  border-color: #d2d2d2;
  border-radius: 0 0 24px 24px;
}

.scrolling {
  right: 7px;
  width: 4px;
}

.jScrollPaneDrag {
  background-color: #181818;
  border-radius: 2px;
}

.custom_select .scroll-wrap {
  max-height: 200px;
  border-radius: 0 0 24px 24px;
  overflow: hidden;
}

.cselect .cs_current, .cselect .scroll-pane span {
  padding-left: 24px;
  padding-right: 24px;
}

.trial {
  width: 650px;
}

.trial-body .trial-cover {
  display: block;
}

.trial .title {
  text-transform: uppercase;
}

@media (max-width: 690px) {
  .trial {
    width: auto;
    margin: 20px;
  }
}

.buy {
  width: 520px;
  padding-top: 40px;
}

@media (max-width: 690px) {
  .buy {
    width: auto;
    margin: 20px;
  }
}

.buy-body .buy-cover {
  display: block;
}

.buy .modal-header {
  margin-bottom: 24px;
}

.buy .title {
  margin-bottom: 8px;
}

.buy-row {
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
}

@media (max-width: 690px) {
  .buy-row {
    display: block;
    height: auto;
    margin-bottom: 20px;
  }
}

.buy .checkbox-item {
  margin-right: 20px;
}

@media (max-width: 690px) {
  .buy .checkbox-item {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.buy .price {
  flex: 0 0 auto;
  font-weight: 700;
}

@media (max-width: 690px) {
  .buy .price {
    padding-left: 50px;
  }
}

.buy .total {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
  padding-top: 14px;
  border-top: 1px solid #d2d2d2;
}

.buy .total .value {
  font-weight: 700;
  font-size: 24px;
}

.buy .form-submit {
  margin-top: 41px;
}

.impressum header {
  color: #181818;
}

.impressum header .logo {
  background-image: url("/i/logo-black.svg");
}

.impressum main {
  padding: 121px 265px 112px 0;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 991px) {
  .impressum main {
    padding: 60px 40px 40px;
  }
}

@media (max-width: 575px) {
  .impressum main {
    padding: 60px 20px 40px;
  }
}

.impressum main h1 {
  margin-bottom: 71px;
  font-weight: 700;
  font-size: 48px;
  line-height: 1;
}

@media (max-width: 575px) {
  .impressum main h1 {
    font-size: 30px;
  }
}

.impressum main h2, .impressum main h3, .impressum main h4, .impressum main h5 {
  margin-bottom: 27px;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

.impressum main h2 {
  font-size: 24px;
  text-align: left;
}

.impressum main a {
  text-decoration: underline;
}

.impressum main a:hover {
  text-decoration: none;
}

.cookies {
  position: fixed;
  right: 0;
  bottom: 50px;
  left: 0;
  z-index: 8;
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  margin: 0 auto;
  padding: 45px 40px 52px;
  box-sizing: border-box;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 24px;
}

@media (max-width: 1103px) {
  .cookies {
    bottom: 40px;
    width: auto;
    margin: 0 40px;
  }
}

@media (max-width: 767px) {
  .cookies {
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media (max-width: 575px) {
  .cookies {
    bottom: 20px;
    margin: 0 20px;
    padding: 28px 24px 30px;
  }
}

.cookies-text {
  margin-right: 100px;
  font-size: 18px;
  line-height: 28px;
}

@media (max-width: 1103px) {
  .cookies-text {
    margin-right: 40px;
  }
}

@media (max-width: 767px) {
  .cookies-text {
    margin-right: 0;
    margin-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .cookies-text {
    font-size: 16px;
    line-height: 24px;
  }
}

.cookies-link {
  border-bottom: 1px solid #ffffff;
}

.cookies .button-secondary {
  padding-top: 10px;
}

.success {
  width: 400px;
  padding-top: 60px;
  text-align: center;
}

@media (max-width: 690px) {
  .success {
    width: auto;
    margin: 20px;
  }
}

.success-body .success-cover {
  display: block;
}

.success .message {
  font-size: 18px;
  margin-bottom: 35px;
}

label.error {
  font-size: 12px;
  color: red;
}
