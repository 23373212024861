$yellow: #ffd71f;
$black: #181818;
$grey: #a0a0a0;

@import url("../fonts/stylesheet.css");

/* http://meyerweb.com/eric/tools/css/reset/
     v2.0 | 20110126
     License: none (public domain) */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* general */
html {
    height:100%;
}
body {
    height:100%;
    min-width:1084px;
    background-color:#fff;
    color:$black;
    font:16px/1.2 Geometria,Arial,Helvetica,sans-serif;

    @media (max-width: 1103px) {
        min-width: 0;
    }
}
.success-body,
.buy-body,
.trial-body {
    overflow:hidden;
}
a {
    color:inherit;
    text-decoration: none;
}
select, input, textarea, button {
    color:inherit;
    font-family:Geometria,Arial,Helvetica,sans-serif;
    font-size: 16px;
}
:focus {
    outline:none;
}
.container {
    width: 1024px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1103px) {
        width: auto;
        padding-right: 40px;
        padding-left: 40px;
    }

    @media (max-width: 575px) {
        padding-right: 20px;
        padding-left: 20px;
    }
}
.svg-sprite {
    display: none;
}
.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.logo {
    width: 146px;
    height: 48px;
    background-position: center center;
    background-repeat: no-repeat;

    @media (max-width: 575px) {
        width: 121px;
        height: 40px;
        background-size: contain;
    }
}

.contacts {
    font-weight: 700;
    text-align: right;
}

.phone {
    font-size: 24px;
}

.email {
    font-size: 18px;
    border-bottom: 1px solid $black;
}

.bg {    
    background: url("/i/bg.jpg") center top no-repeat;
    background-size: cover;

    @media (max-width: 880px) {
        background-image: url("/i/bg-mobile.jpg");
    }

    @media (max-width: 320px) {
        background-image: url("/i/bg-320.jpg");
    }
}

@mixin button {
    position: relative;
    display: inline-block;
    height: 48px;
    padding: 0 55px 0 22px;
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    vertical-align: middle;
    box-sizing: border-box;
    text-decoration: none;
    background: $yellow;
    border-radius: 24px;
    box-shadow: none;
    border: 2px solid $black;
    cursor: pointer;
    white-space: nowrap;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 22px;
        width: 18px;
        height: 14px;
        margin-top: -7px;
        background: url("../i/arrow.svg") center center no-repeat;
        transition: right 0.2s ease;
    }

    &:hover {
        text-decoration: none;

        &::after {
            right: 14px;
        }
    }
}

@mixin button-secondary {
    @include button;
    background: none;
    border-color: $yellow;

    &::after {
        background-image: url("../i/arrow-white.svg");
    }
}

@mixin button-narrow {
    padding-right: 39px;

    &::after {
        right: 14px;
    }

    &:hover {
        &::after {
            right: 8px;
        }
    }
}

@mixin button-yellow {
    border: none;
}

.button {
    @include button;
}

.button-secondary {
    @include button-secondary;
}

.button-narrow {
    @include button-narrow;
}

.button-yellow {
    @include button-yellow;
}

h2 {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
}

header,
.start {
    color: #ffffff;
}

header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 29px;

    @media (max-width: 767px) {
        align-items: center;
        padding-top: 43px;
    }

    .logo {
        background-image: url('/i/logo-white.svg');
    }

    .contacts {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .email {
        border-bottom-color: #ffffff;
    }
}

.lang-switcher {
    width: 100%;
    margin-bottom: 16px;
    text-align: right;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 767px) {
        width: auto;
        order: 2;
        margin-bottom: 0;
    }

    li {
        display: inline-block;

        &:first-child {
            margin-right: 2px;
        }

        a {
            opacity: 0.5;
            transition: opacity 0.2s ease;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.start {
    padding-top: 146px;
    padding-bottom: 216px;
    text-align: center;

    .en & {
        width: 900px;

        @media (max-width: 1103px) {
            width: auto;
        }
    }

    @media (max-width: 880px) {
        padding-top: 80px;
        padding-bottom: 120px;
    }

    @media (max-width: 767px) {
        padding-top: 41px;
        padding-bottom: 47px;
    }

    @media (max-width: 575px) {
        text-align: left;
    }

    h1 {
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 96px;
        line-height: 1;

        @media (max-width: 880px) {
            font-size: 64px;
            line-height: 54px;
        }

        @media (max-width: 767px) {
            margin-bottom: 29px;
        }
    }

    p {
        font-weight: 500;
        font-size: 48px;

        @media (max-width: 880px) {
            font-size: 24px;
            line-height: 32px;
        }
    }
}

.line {
    background: $yellow;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 96px;

        @media (max-width: 1103px) {
            display: block;
            height: auto;
            padding-top: 30px;
            padding-bottom: 37px;
        }
    }

    .text {
        font-size: 22px;
        line-height: 30px;

        @media (max-width: 1103px) {
            margin-bottom: 35px;
        }
    }
}

.services {
    margin-bottom: 116px;
    padding-top: 108px;

    @media (max-width: 767px) {
        padding-top: 52px;
    }

    @media (max-width: 575px) {
        margin-bottom: 82px;
    }

    h2 {
        margin-bottom: 66px;
        font-size: 40px;

        @media (max-width: 767px) {
            margin-bottom: 41px;
            font-size: 30px;
        }
    }

    li {
        display: flex;
        align-items: flex-end;
        padding: 35px 24px 51px 48px;
        color: #ffffff;
        border-radius: 32px;
        background-color: #221914;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 880px) {
            display: block;
        }

        @media (max-width: 767px) {
            padding: 37px 12px 38px;
            border-radius: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 60px;

            @media (max-width: 575px) {
                margin-bottom: 24px;
            }
        }

        @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
                background-image: url('/i/services#{$i}.jpg');
            }
        }
    }

    .col {
        &:first-child {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            width: 518px;
            height: 426px;

            @media (max-width: 1103px) {
                width: 55%;
                height: auto;
            }

            @media (max-width: 880px) {
                width: auto;
                margin-bottom: 45px;
            }
        }

        &:last-child {
            flex: 1 1 auto;

            @media (max-width: 1103px) {
                flex: 0 0 auto;
                width: 45%;
            }

            @media (max-width: 880px) {
                width: auto;
            }
        }

        &-alt {
            padding-left: 61px;

            @media (max-width: 1103px) {
                padding-left: 0;
            }
        }
    }

    h3 {
        margin-bottom: 14px;
        font-weight: 700;
        font-size: 48px;
        line-height: 1;

        @media (max-width: 767px) {
            font-size: 32px;
            line-height: 36px;
        }
    }

    .subtitle {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 30px;

        @media (max-width: 1103px) {
            padding-right: 24px;
        }

        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    .text {
        margin-top: auto;
        padding-right: 48px;
        line-height: 24px;

        @media (max-width: 880px) {
            padding-right: 0;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .price {
        display: flex;
        flex-wrap: wrap;
        padding-right: 24px;

        @media (max-width: 767px) {
            padding-right: 12px;
        }

        @media (max-width: 575px) {
            display: block;
            margin-bottom: 33px;
        }

        &-item {
            display: flex;
            align-items: flex-end;
            margin-bottom: 25px;

            @media (max-width: 1103px) {
                flex-wrap: wrap;
            }

            @media (max-width: 575px) {
                display: block;
                margin-bottom: 14px;
            }

            &:first-child {
                margin-right: 30px;
            }
        }

        br {
            @media (max-width: 575px) {
                display: none;
            }
        }
    }

    .value {
        margin-right: 10px;
        font-weight: 700;
        font-size: 52px;
        line-height: 47px;

        @media (max-width: 767px) {
            font-size: 48px;
        }
    }

    .property {
        font-size: 14px;
        line-height: 20px;

        @media (max-width: 1103px) {
            white-space: nowrap;
            margin-top: 5px;
        }

        @media (max-width: 575px) {
            white-space: normal;
        }
    }

    .buttons {
        margin-bottom: 15px;

        @media (max-width: 575px) {
            margin-bottom: 7px;
        }
    }

    button {
        margin-bottom: 20px;

        &:first-child {
            margin-right: 10px;

            @media (max-width: 360px) {
                &.button-narrow {
                    margin-right: 0;
                }
            }
        }
    }

    .button-narrow {
        @media (max-width: 575px) {
            padding-right: 37px;
            padding-left: 10px;

            &::after {
                right: 12px;
            }
        }
    }

    .tip {
        font-size: 14px;
        line-height: 18px;
        padding-right: 24px;

        @media (max-width: 767px) {
            padding-right: 12px;
        }

        &-alt {
            margin-bottom: 65px;
            font-size: 24px;
            line-height: 30px;
        }
    }
}

.files {
    margin-bottom: 103px;

    @media (max-width: 575px) {
        margin-bottom: 56px;
    }

    h2 {
        margin-bottom: 41px;

        @media (max-width: 575px) {
            margin-bottom: 45px;
        }
    }

    ul {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1103px) {
            flex-wrap: wrap;
        }
    }

    li {
        flex: 0 0 auto;

        @media (max-width: 1103px) {
            width: 33.3333%;
            margin-bottom: 16px;
        }

        @media (max-width: 575px) {
            width: 50%;
        }
    }

    a {
        display: block;
        width: 154px;
        text-align: center;
        padding: 12px 0 14px;
        border-radius: 24px;
        line-height: 24px;

        @media (max-width: 1103px) {
            margin: 0 auto;
        }

        @media (max-width: 575px) {
            width: 120px;
        }

        &:hover {
            background: $yellow;
            box-shadow: 0 14px 28px 0 #ffe2bd;

            span, p {
                color: #000000;
            }
        }
    }

    span {
        display: inline-block;
        margin-bottom: 3px;
        font-size: 18px;
        line-height: 1;
        border-bottom: 1px solid $black;
    }

    p {
        font-size: 14px;
        color: $grey;
    }
}

.teachers {
    margin-bottom: 100px;

    @media (max-width: 1103px) {
        margin-bottom: 78px;
    }

    h2 {
        margin-bottom: 41px;

        @media (max-width: 575px) {
            margin-bottom: 47px;
        }
    }

    ul {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1103px) {
            flex-wrap: wrap;
            justify-content: space-around;
        }

        @media (max-width: 575px) {
            display: block;
        }
    }

    li {
        @media (max-width: 1103px) {
            flex: 0 0 auto;
            width: 50%;
            margin-bottom: 35px;
        }

        @media (max-width: 575px) {
            width: auto;
        }
    }

    img {
        display: block;
        width: 225px;
        height: 225px;
        margin: 0 auto 21px;
        border-radius: 24px;
        box-shadow: 0 14px 28px 0 #f0f0f0;
    }

    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
    }
}

.features {
    margin-bottom: 45px;

    @media (max-width: 575px) {
        margin-bottom: 62px;
    }

    h2 {
        margin-bottom: 56px;

        @media (max-width: 575px) {
            margin-bottom: 44px;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -31px;
        counter-reset: features;
        text-align: center;

        @media (max-width: 1103px) {
            margin: 0 -20px;
        }
    }

    li {
        flex: 0 0 auto;
        width: 33.3333%;
        margin-bottom: 66px;
        padding: 0 31px;
        box-sizing: border-box;
        line-height: 20px;

        @media (max-width: 1103px) {
            width: 360px;
            max-width: 100%;
            margin-bottom: 43px;
            padding: 0 20px;
        }

        &::before {
            counter-increment: features;
            content: "0" counter(features);
            display: block;
            margin-bottom: 17px;
            font-weight: 900;
            font-size: 48px;
            line-height: 0.75;
            color: $yellow;
        }
    }

    .title {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 18px;
    }
}

.reviews {
    position: relative;
    margin-bottom: 137px;

    @media (max-width: 640px) {
        margin-bottom: 63px;
    }

    h2 {
        margin-bottom: 40px;

        br {
            @media (min-width: 640px) {
                display: none;
            }
        }
    }

    .text {
        margin-bottom: 20px;
        line-height: 24px;
    }

    .author {
        font-weight: 700;
    }
}

.carousel {
    width: 600px;
    margin: 0 auto;
    padding: 36px 60px 78px;
    border-radius: 24px;
    box-shadow: 0 14px 28px 0 #f0f0f0;

    @media (max-width: 880px) {
        width: auto;
    }

    @media (max-width: 767px) {
        padding: 40px 24px 78px;
    }

    &-progress {
        position: absolute;
        right: 0;
        bottom: 27px;
        left: 0;
        font-weight: 700;
        font-size: 12px;
        color: $grey;
        text-align: center;

        @media (max-width: 575px) {
            bottom: 37px;
        }
    }
}

.owl {
    &-carousel {
        .owl-nav {
            button.owl-prev,
            button.owl-next {
                position: absolute;
                top: 50%;
                margin-top: -24px;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: $yellow url('/i/arrow.svg') center center no-repeat;
                background-size: 19px 13px;

                @media (max-width: 767px) {
                    top: -81px;
                    margin-top: 0;
                }

                @media (max-width: 639px) {
                    top: -120px;

                    .en & {
                        top: -78px;
                    }
                }
            }

            button.owl-prev {
                transform: rotate(180deg);
                left: -24px;

                @media (max-width: 767px) {
                    left: 0;
                }
            }

            button.owl-next {
                right: -24px;

                @media (max-width: 767px) {
                    right: 0;
                }
            }
        }
    }
}

footer {
    padding-top: 50px;
    padding-bottom: 86px;

    @media (max-width: 767px) {
        text-align: center;
    }

    @media (max-width: 575px) {
        padding-top: 45px;
        padding-bottom: 75px;
    }

    .logo {
        background-image: url('/i/logo-black.svg');

        @media (max-width: 767px) {
            display: none;
        }
    }

    .contacts {
        @media (max-width: 767px) {
            text-align: center;
        }
    }
}

.footer {
    &-top {
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            display: block;
            margin-bottom: 37px;
        }
    }

    &-bottom {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;

        @media (max-width: 767px) {
            display: block;
        }
    }    
}

.links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 767px) {
        display: block;
        margin-bottom: 29px;
    }

    a {
        font-size: 14px;
        color: inherit;
        border-bottom: 1px solid $black;
        text-decoration: none;
    }
}

.address {
    text-align: right;

    @media (max-width: 767px) {
        margin-bottom: 26px;
        text-align: center;
    }
}

.copy {
    font-size: 13px;

    &-desktop {
        @media (max-width: 767px) {
            display: none;
        }
    }

    &-mobile {
        @media (min-width: 768px) {
            display: none;
        }
    }
}

.trial-opener {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -96px;
    z-index: 9;
    margin: auto 0;
    border-radius: 0 0 24px 24px;
    width: 240px;
    height: 48px;
    background-color: $yellow;
    font-weight: bold;
    line-height: 47px;
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);

    @media (max-width: 767px) {
        display: none;
    }
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none;
    text-align: center;
    background: rgba(0, 0, 0, 0.65);
    overflow-y: auto;

    &::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;

        @media (max-width: 690px) {
            display: none;
        }
    }
}

.modal {
    position: relative;
    display: inline-block;
    padding: 47px 40px 40px;
    box-sizing: border-box;
    text-align: left;
    vertical-align: middle;
    border-radius: 24px;
    background: #ffffff;
    @media (max-width: 690px) {
        padding: 67px 24px 56px;
    }

    .tip {
        margin-bottom: 20px;
        line-height: 20px;
    }

    .title {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
    }

    &-header {        
        margin-bottom: 60px;

        @media (max-width: 690px) {
            margin-bottom: 45px;
        }
    }
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    background: url("/i/close.svg") no-repeat;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.form {
    &-cols {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 690px) {
            display: block;
        }
        
        .form-item {
            width: 250px;
            margin-right: 20px;            

            @media (max-width: 690px) {
                width: auto;
                margin-right: 0;
            }

            &:not(:last-child) {
                margin-bottom: 24px;

                @media (max-width: 690px) {
                    margin-bottom: 20px;
                }
            }
        }

        .textarea-item {
            width: 520px;

            @media (max-width: 690px) {
                width: auto;
                margin-right: 0;
            }
        }
    }

    &-item {
        height: 48px;
    }

    &-submit {
        margin-top: 60px;

        @media (max-width: 690px) {
            margin-top: 52px;
        }
    }
}

.textarea-item {
    height: 120px;
}

select,
input,
textarea {
    margin:0;
    height: 100%;
    width: 100%;
    border:1px solid #d2d2d2;
    padding:0 24px;
    box-sizing:border-box;
    border-radius: 24px;

        &::-webkit-input-placeholder {
            color: $grey;
            font-size: 16px;
        }
        &::-moz-placeholder { 
            color: $grey;
            font-size: 16px;
        }
        &:-ms-input-placeholder {
            color: $grey;
            font-size: 16px;
        }
        &:-moz-placeholder {
            color: $grey;
            font-size: 16px;
        }
        &::placeholder {
            color: $grey;
            font-size: 16px;
        }
}
textarea {
    padding-top: 15px;
    padding-bottom: 15px;
    resize: none;
}
.checkbox {
    &-item {
        input {
            display: none;
        }
    }

    &-label {
        position: relative;
        display: block;
        padding-left: 50px;
        font-weight: 500;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 32px;
            height: 32px;
            margin-top: -16px;
            border-radius: 6px;
            border: 1px solid #d2d2d2;

            input:checked ~ & {
                border: none;
                background: $yellow url("/i/check.svg") center center no-repeat;
            }

            @media (max-width: 690px) {
                top: 3px;
                margin-top: 0;
            }
        }   
    }
}

.cselect {
    font-size: 16px;
}
.custom_select {
    border:1px solid #d2d2d2;
    box-sizing:border-box;
    border-radius: 24px;
    font-weight: 400;
}
.custom_select .cs_current_after {
    top: 50%;
    right:24px;
    width: 0;
    height: 0;
    margin-top: -3px;
    border-width: 6px 5px 0 5px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent;
}
.custom_select.active .cs_current_after {
    transform: rotate(180deg);
}
.custom_select .scroll-container {
    max-height: 200px;
    margin:0 -1px;
    border-width:1px;
    background-color: #ffffff;
    border-color: #d2d2d2;
    border-radius: 0 0 24px 24px;
}
.scrolling {
    right: 7px;
    width: 4px;
}
.jScrollPaneDrag {
    background-color: $black;
    border-radius: 2px;
}
.custom_select .scroll-wrap {
    max-height: 200px;
    border-radius: 0 0 24px 24px;
    overflow: hidden;
}

.cselect .cs_current, .cselect .scroll-pane span {
    padding-left: 24px;
    padding-right: 24px;
}

.trial {
    width: 650px;

    &-cover {
        .trial-body & {
            display: block;
        }
    }

    .title {
        text-transform: uppercase;
    }

    @media (max-width: 690px) {
        width: auto;
        margin: 20px;
    }
}

.buy {
    width: 520px;
    padding-top: 40px;

    @media (max-width: 690px) {
        width: auto;
        margin: 20px;
    }

    &-cover {
        .buy-body & {
            display: block;
        }
    }

    .modal-header {
        margin-bottom: 24px;
    }

    .title {
        margin-bottom: 8px;
    }

    &-row {
        display: flex;
        justify-content: space-between;
        height: 56px;
        align-items: center;

        @media (max-width: 690px) {
            display: block;
            height: auto;
            margin-bottom: 20px;
        }
    }

    .checkbox-item {
        margin-right: 20px;

        @media (max-width: 690px) {
            margin-right: 0;
            margin-bottom: 16px;
        }   
    }

    .price {
        flex: 0 0 auto;
        font-weight: 700;

        @media (max-width: 690px) {
            padding-left: 50px;
        }
    }

    .total {
        display: flex;
        justify-content: space-between;
        margin-top: 26px;
        padding-top: 14px;
        border-top: 1px solid #d2d2d2;

        .value {
            font-weight: 700;
            font-size: 24px;
        }
    }

    .form-submit {
        margin-top: 41px;
    }
}

.impressum {
    header {
        color: $black;

        .logo {
            background-image: url("/i/logo-black.svg");
        }
    }

    main {
        padding: 121px 265px 112px 0;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 24px;

        @media (max-width: 991px) {
            padding: 60px 40px 40px;
        }

        @media (max-width: 575px) {
            padding: 60px 20px 40px;
        }

        h1 {
            margin-bottom: 71px;
            font-weight: 700;
            font-size: 48px;
            line-height: 1;

            @media (max-width: 575px) {
                font-size: 30px;
            }
        }

        h2, h3, h4, h5 {
            margin-bottom: 27px;
            font-weight: 700;
            font-size: 18px;
            text-transform: uppercase;
        }

        h2 {
            font-size: 24px;
            text-align: left;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.cookies {
    position: fixed;
    right: 0;
    bottom: 50px;
    left: 0;
    z-index: 8;
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 960px;
    margin: 0 auto;
    padding: 45px 40px 52px;
    box-sizing: border-box;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 24px;

    @media (max-width: 1103px) {
        bottom: 40px;
        width: auto;
        margin: 0 40px;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
    }

    @media (max-width: 575px) {
        bottom: 20px;
        margin: 0 20px;
        padding: 28px 24px 30px;
    }

    &-text {
        margin-right: 100px;
        font-size: 18px;
        line-height: 28px;

        @media (max-width: 1103px) {
            margin-right: 40px;
        }

        @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 40px;
        }

        @media (max-width: 575px) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &-link {
        border-bottom: 1px solid #ffffff;
    }

    .button-secondary {
        padding-top: 10px;
    }
}

.success {
    width: 400px;
    padding-top: 60px;
    text-align: center;

    @media (max-width: 690px) {
        width: auto;
        margin: 20px;
    }

    &-cover {
        .success-body & {
            display: block;
        }
    }

    .message {
        font-size: 18px;
        margin-bottom: 35px;
    }
}

label.error {
    font-size: 12px;
    color: red;
}